import moment from 'moment/moment';
import { lifespanOptions } from '@/modules/ReusePotentialIndex/lifespan';

export const calculateFunctionalDisconnection = (components) => {
  for (const c of components) {
    if (c.generic_component === 'ja') {
      c.functionalDisconnectionScore = 0.9;
      continue;
    }
    if (c.component_additional_function === 'ja') {
      c.functionalDisconnectionScore = 0.1;
      continue;
    }

    c.functionalDisconnectionScore = 0.7;
  }
  const genericFunctionalityPercent =
    components.filter((c) => c.generic_component === 'ja').length /
    components.length;
  const additionalBuildingFunctionalityCount =
    components.filter((c) => c.building_additional_function === 'ja').length /
    components.length;
  const additionalComponentFunctionalityCount =
    components.filter((c) => c.component_additional_function === 'ja').length /
    components.length;

  const genericBuildingMaxFSr = 0.3;
  const singleMaxFSr = 0.7;
  const multipleMaxFSr = 0.5;

  const x =
    (genericFunctionalityPercent / genericBuildingMaxFSr > 1
      ? 1
      : genericFunctionalityPercent / genericBuildingMaxFSr) * 0.2;

  const y =
    0.7 -
    (additionalBuildingFunctionalityCount / multipleMaxFSr > 1
      ? 1
      : additionalBuildingFunctionalityCount / multipleMaxFSr) *
      -0.6;

  const z =
    0.7 -
    (additionalComponentFunctionalityCount / multipleMaxFSr > 1
      ? 1
      : additionalComponentFunctionalityCount / multipleMaxFSr) *
      -0.6;

  const calculation = parseFloat((x + (y + z) / 2).toFixed(2));

  return Number.isNaN(calculation) ? 0 : calculation;
};

export const calculateProductLevels = (components) => {
  // A mapping dictionary is created to easily map product levels to their respective scores.
  let productLevels = {
    'materialen (downcyclebaar)': 0.1,
    'materialen (recyclebaar/biologisch afbreekbaar)': 0.3,
    elementen: 0.5,
    'componenten/systemen': 0.9,
  };

  let uniqueScores = new Set();

  components.forEach((c) => {
    if (c.product_lvl in productLevels) {
      c.productLevelScore = productLevels[c.product_lvl];
      uniqueScores.add(c.productLevelScore);
    }
  });

  uniqueScores = Array.from(uniqueScores);
  const totalProductLevelScores = uniqueScores.reduce(
    (acc, score) => acc + score,
    0
  );

  const score = parseFloat(
    (totalProductLevelScores / uniqueScores.length).toFixed(2)
  );

  return Number.isNaN(score) ? 0 : score;
};

export const calculateTypeClustering = (components) => {
  const isPartOfComponentSystem = (component) =>
    component.product_lvl === 'componenten/systemen';
  const relevantComponentsRatio =
    (components.filter(isPartOfComponentSystem).length / components.length) *
    100;

  const UPPER_THRESHOLD_VERY_HIGH = 70;
  const UPPER_THRESHOLD_HIGH = 40;
  const UPPER_THRESHOLD_MEDIUM = 20;

  if (relevantComponentsRatio >= UPPER_THRESHOLD_VERY_HIGH) {
    return 0.9;
  } else if (relevantComponentsRatio >= UPPER_THRESHOLD_HIGH) {
    return 0.6;
  } else if (relevantComponentsRatio >= UPPER_THRESHOLD_MEDIUM) {
    return 0.3;
  } else {
    return 0.1;
  }
};

export const calculateLifespanLeft = (constructionDate, components) => {
  const isBefore1940 = moment(constructionDate).isBefore(moment('1940-01-01'));
  components.forEach((c) => {
    const item = lifespanOptions.find((x) => x.id === c.technical_lifespan);

    const lifespan = isBefore1940
      ? item?.yearsBefore ?? item.years
      : item.years;

    const yearsLeft =
      lifespan - moment(constructionDate).diff(moment(), 'years') ?? 0;

    if (yearsLeft > 20) {
      c.lifespanScore = 0.9;
    }
    if (yearsLeft > 10 && yearsLeft <= 20) {
      c.lifespanScore = 0.7;
    }
    if (yearsLeft > 5 && yearsLeft <= 10) {
      c.lifespanScore = 0.5;
    }
    if (yearsLeft > 0 && yearsLeft <= 5) {
      c.lifespanScore = 0.3;
    }
    if (yearsLeft === 0) {
      c.lifespanScore = 0.1;
    }
  });

  const totalLifespanScores = components.reduce(
    (acc, c) => acc + c.lifespanScore,
    0
  );
  const score = parseFloat(
    (totalLifespanScores / components.length).toFixed(2)
  );

  return Number.isNaN(score) ? 0 : score;
};

export const calculateConnectionTypes = (components, connections) => {
  let connectionTypes = [];
  for (const c of components) {
    let types = connections.filter((x) => x.from_component === c.id);
    if (types.length === 0) {
      c.connectionTypeScore = -1;
      continue;
    }

    let score = 0;
    for (const t of types) {
      if (['D2', 'D4'].includes(t.type)) {
        score += 0.2;
        connectionTypes.push(1);
        continue;
      }
      if (['D3'].includes(t.type)) {
        score += 0.2;
        connectionTypes.push(2);
        continue;
      }
      if (['D1'].includes(t.type)) {
        score += 0.3;
        connectionTypes.push(3);
        continue;
      }
      if (['C2', 'C3', 'C5'].includes(t.type)) {
        score += 0.4;
        connectionTypes.push(4);
        continue;
      }
      if (['C1'].includes(t.type)) {
        score += 0.7;
        connectionTypes.push(5);
        continue;
      }
      if (['B2', 'C4'].includes(t.type)) {
        score += 0.8;
        connectionTypes.push(6);
        continue;
      }
      if (['B1'].includes(t.type)) {
        score += 0.8;
        connectionTypes.push(7);
        continue;
      }
      if (['A1', 'A2', 'A3'].includes(t.type)) {
        score += 0.9;
        connectionTypes.push(9);
        continue;
      }

      if (c.component_function === 'intermediair') {
        // score += 0.9;
        connectionTypes.push(8);
        // continue;
      }
    }

    c.connectionTypeScore = parseFloat((score / types.length).toFixed(2)) ?? 0;
    if (Number.isNaN(c.connectionTypeScore)) {
      c.connectionTypeScore = 0;
    }
  }

  const type1Percent =
    connectionTypes.filter((x) => x === 1).length / connectionTypes.length;
  const type2Percent =
    connectionTypes.filter((x) => x === 2).length / connectionTypes.length;
  const type3Percent =
    connectionTypes.filter((x) => x === 3).length / connectionTypes.length;
  const type4Percent =
    connectionTypes.filter((x) => x === 4).length / connectionTypes.length;
  const type5Percent =
    connectionTypes.filter((x) => x === 5).length / connectionTypes.length;
  const type6Percent =
    connectionTypes.filter((x) => x === 6).length / connectionTypes.length;
  const type7Percent =
    connectionTypes.filter((x) => x === 7).length / connectionTypes.length;
  const type8Percent =
    connectionTypes.filter((x) => x === 8).length / connectionTypes.length;
  const type9Percent =
    connectionTypes.filter((x) => x === 9).length / connectionTypes.length;

  const score =
    0.9 -
    (Number.isNaN(type1Percent) ? 0 : type1Percent / 0.7) * 0.7 -
    (Number.isNaN(type2Percent) ? 0 : type2Percent / 0.7) * 0.7 -
    (Number.isNaN(type3Percent) ? 0 : type3Percent / 1) * 0.6 -
    (Number.isNaN(type4Percent) ? 0 : type4Percent / 1) * 0.5 -
    (Number.isNaN(type5Percent) ? 0 : type5Percent / 1) * 0.2 -
    (Number.isNaN(type6Percent) ? 0 : type6Percent / 1) * 0.1 -
    (Number.isNaN(type7Percent) ? 0 : type7Percent / 1) * 0.1;
  // (Number.isNaN(type8Percent) ? 0 : type8Percent / 1) * 0 -
  // (Number.isNaN(type9Percent) ? 0 : type9Percent / 1) * 0;

  return parseFloat(score.toFixed(2)) ?? 0;
};

export const calculateConnectionDamages = (components, connections) => {
  // calculate the highest damages from all connections both from and to
  for (const c of components) {
    // console.log({ ...c });
    // get from connections
    let connectFrom = connections.filter((x) => x.from_component === c.id);
    let connectTo = connections.filter((x) => x.to_component === c.id);

    if (connectTo.some((y) => ['D2', 'D3', 'D4'].includes(y.type))) {
      c.damageLevel = 5;
      continue;
    }
    if (
      connectFrom.some((y) => ['D1', 'D2', 'D3', 'D4'].includes(y.type)) ||
      connectTo.some((y) => ['D1'].includes(y.type))
    ) {
      c.damageLevel = 4;
      continue;
    }

    if (c.component_function !== 'intermediair') {
      if (
        [...connectFrom, ...connectTo].some((y) =>
          ['C2', 'C3', 'C5'].includes(y.type)
        )
      ) {
        c.damageLevel = 3;
        continue;
      }
    }

    if ([...connectFrom, ...connectTo].some((y) => ['C1'].includes(y.type))) {
      c.damageLevel = 2;
      continue;
    }

    c.damageLevel = 1;
  }

  components.forEach((c) => {
    switch (c.damageLevel) {
      case 5:
        c.connectionDamageScore = 0.1;
        break;
      case 4:
        c.connectionDamageScore = 0.3;
        break;
      case 3:
        c.connectionDamageScore = 0.5;
        break;
      case 2:
        c.connectionDamageScore = 0.7;
        break;
      case 1:
        c.connectionDamageScore = 0.9;
        break;
    }
  });

  const totalConnectionDamageScores = components.reduce(
    (acc, c) => acc + c.connectionDamageScore,
    0
  );
  const score = parseFloat(
    (totalConnectionDamageScores / components.length).toFixed(2)
  );
  return Number.isNaN(score) ? 0 : parseFloat(score.toFixed(2));
};

export const calculateComponentConnections = (components, connections) => {
  components.forEach((c) => {
    let connectionCount = connections.filter(
      (y) => y.from_component === c.id
    ).length;
    if (connectionCount <= 2) {
      c.componentConnectionScore = 0.9;
    }
    if (connectionCount > 2 && connectionCount <= 4) {
      c.componentConnectionScore = 0.6;
    }
    if (connectionCount > 4 && connectionCount <= 6) {
      c.componentConnectionScore = 0.3;
    }
    if (connectionCount > 6) {
      c.componentConnectionScore = 0.1;
    }
  });

  const averageConnections = connections.length / components.length;

  if (averageConnections <= 2) {
    return 0.9;
  }
  if (2 < averageConnections && averageConnections <= 4) {
    return 0.6;
  }
  if (4 < averageConnections ** averageConnections <= 6) {
    return 0.3;
  }
  if (6 <= averageConnections) {
    return 0.1;
  }
};
