export const variantsAndCrossSectionsQuery = (projectId, variantId) => {
  return {
    tables: [
      {
        name: 'CFFA_RPI_VARIANTS',
        project: projectId,
        as: 'variants',
        records: [variantId],
        columns: [
          {
            name: 'name',
          },
          {
            name: 'contact',
          },
          {
            name: 'construction_date',
          },
        ],
      },
      {
        name: 'CFFA_RPI_CROSS_SECTIONS',
        project: projectId,
        as: 'crossSections',
        sortBy: 'title',
        columns: [
          {
            name: 'variant',
            conditions: [
              {
                operator: '=',
                value: variantId,
              },
            ],
          },
          {
            name: 'title',
          },
          {
            name: 'image',
          },
          {
            name: 'coverage_ratio',
          },
          {
            name: 'score',
          },
          {
            name: 'score_revision_time',
          },
        ],
      },
    ],
  };
};
export const crossSectionComponentsQuery = (projectId, crossSectionId) => {
  return {
    tables: [
      {
        name: 'CFFA_RPI_COMPONENTS',
        project: projectId,
        as: 'components',
        sortBy: 'nr',
        columns: [
          {
            name: 'cross_section',
            conditions: [
              {
                operator: '=',
                value: crossSectionId,
              },
            ],
          },
          {
            name: 'nr',
          },
          {
            name: 'name',
          },
          {
            name: 'description',
          },
          {
            name: 'status',
          },
          {
            name: 'product_lvl',
          },
          {
            name: 'component_function',
          },
          {
            name: 'component_additional_function',
          },
          {
            name: 'building_function',
          },
          {
            name: 'building_additional_function',
          },
          {
            name: 'generic_component',
          },
          {
            name: 'construction_date_deviation',
          },
          {
            name: 'technical_lifespan',
          },
        ],
      },
    ],
  };
};

export const connectionsByComponentsQuery = (projectId, componentIds) => {
  return {
    tables: [
      {
        name: 'CFFA_RPI_COMPONENTS_CONNECTIONS',
        project: projectId,
        as: 'connections',
        columns: [
          {
            name: 'to_component',
          },
          {
            name: 'from_component',
            conditions: [
              {
                operator: '=',
                values: componentIds,
              },
            ],
          },
          {
            name: 'type',
          },
        ],
      },
    ],
  };
};
export const connectionTypes = [
  {
    type: 'A1',
    label: 'Zwaartekracht',
  },
  {
    type: 'A2',
    label: 'Zwaartekracht met bevestiging',
  },
  {
    type: 'A3',
    label: 'Intermediair',
  },
  {
    type: 'B1',
    label: 'Directe vormvergrendeling (vergrendeling, klik, wig, klem)',
  },
  {
    type: 'B2',
    label: 'Vormsluiting met bevestiging (beugel, glaslat)',
  },
  {
    type: 'C1',
    label: 'Directe tweevoudige toegang (bout-moer)',
  },
  {
    type: 'C2',
    label: 'Enkelvoudige toegang met schroefdraad (schroef)',
  },
  {
    type: 'C3',
    label: 'Enkelvoudige toegang zonder schroefdraad (spijker, nagel)',
  },
  {
    type: 'C4',
    label: 'Verborgen deuvel',
  },
  {
    type: 'C5',
    label: 'Onomkeerbare enkelvoudige toegang (klinknagel, crimper)',
  },
  {
    type: 'D1',
    label:
      'Materiaal dat zwakker is dan het verbonden deel (mortel, lijm, tape)',
  },
  {
    type: 'D2',
    label:
      'Bedekkingsmateriaal, zwakker dan verbonden deel (verf, pleister, dekvloer)',
  },
  {
    type: 'D3',
    label: 'Materiaal sterker dan verbonden deel (cement)',
  },
  {
    type: 'D4',
    label: '( Bedekkings) materiaal, sterker dan aangesloten deel (stortbeton)',
  },
];
